<template>
  <div style="margin: 0 auto;">

    <div id="carouselExampleIndicators" class="carousel slide"  style="margin-top:75px;">
      <!-- <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
          aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
          aria-label="Slide 3"></button>
      </div> -->
      <div class="carousel-inner">
        <div class="carousel-item active" v-for="(item,index) in TopbroadcastList" :key="index">
          <img :src="'imgs' + item.url" class="d-block w-100" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <div class="ProductBox container">
      <div class="Listbox row">
        <div class="lablist col-lg-6 col-sm-12 mx-auto">
          <div style="" class="hengx"></div>
          <div class="text Headgs">山东创智电梯</div>
          <div class="text EngHead">SHANDONG CHUANGZHI ELEVATOR</div>
          <div class="text gsText">
            创智电梯，作为家用电梯领域的创新先锋，始终秉承“创新驱动，智领未来"的品牌理念，致力于将前沿科技与优雅设计完美融合于每一部家用电梯之中。我们深知，在这个追求高效、便捷与品质生活的时代，家用电梯已不仅仅是垂直交通的工具，更是现代家庭品质生活的象征。在创新驱动下，创智电梯不断探索电梯技术的边界，从智能控制系统到安全性能优化，再到环保节能材料的运用，我们不断突破自我，引领行业前行。我们的家用电梯搭载了先进的智能技术，支持多样化的操作方式，如语音控制、手机APP远程操控等，让每一次乘坐都充满未来感与便捷性。
          </div>
          <div class="text texBott row">
            <div class="col-lg-5 col-sm-12" style="margin-right: 85px">
              <div style="" class="centcont">
                <div style="font-size: 60px;">十佳</div>
                <div style="" class="pinptext">品牌</div>
              </div>
              <div style="" class="smalltext">
                中国家用电梯(别墅)
              </div>
            </div>
            <div class="col-lg-5 col-sm-12">
              <div style="font-size: 60px">5000+</div>
              <div style="" class="righttext">服务站点</div>
            </div>
          </div>
        </div>
        <div class="lablist col-lg-4 col-sm-12 mx-auto">
          <img style="" class="rightimg" src="../assets/banner/profile.png" alt="">
        </div>
      </div>

    </div>
    <div style="" class="botText">
      <div class=" container" style="min-height: 333px;">
        <div class="Listbox row botBox" style="">
          <div class=" col-lg-6 col-sm-12 mx-auto" style="font-size: 45px;">
            <div>创新驱动</div>
            <div>智领未来</div>
          </div>
          <div class=" col-lg-6 col-sm-12 mx-auto BriefBox" style="">
            <div>
              基于遍布全国的维保工程师团队和行业领先的维保体系为您提供
            </div>
            <div>
              豪华酒店管家式的五星级服务
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background-color: #e6e6e6;position: relative;">
      <div class="banner">
        <div class="swiper-container">
          <div class="swiper-wrapper" id="lbt">
            <!-- <div class="swiper-slide blue-slide" v-for="(item,index) in BroadcastList" :key="index" @click="mySwiper.swipePrev()" style="padding-left: 30px;padding-right: 30px;width: 80%;">
            <div class="banDetail">
              <a class="pic" href="#" target="_blank">
                <img src="../assets/banner/profbot.png" alt="" style="width: 100%;" />
              </a>
            </div>
          </div> -->
            <!-- <div class="swiper-slide orange-slide" @click="mySwiper.swipePrev()"
              style="padding-left: 30px;padding-right: 30px;width: 80%;">
              <div class="banDetail">
                <a class="pic" href="#" target="_blank">
                  <img src="../assets/banner/profbot.png" alt="" style="width: 100%;" />
                </a>
              </div>
            </div>
            <div class="swiper-slide orange-slide" @click="mySwiper.swipePrev()"
              style="padding-left: 30px;padding-right: 30px;width: 80%;">
              <div class="banDetail">
                <a class="pic" href="#" target="_blank">
                  <img src="../assets/banner/profbot.png" alt="" style="width: 100%;" />
                </a>
              </div>
            </div> -->
            <div class="pagination"></div>
          </div>
        </div>
      </div>

      <div style="position: absolute;left: 35%;color: #fff;z-index: 100;top: 75%;width: 30%;" class="d-none d-lg-block">
        <div class="row">
          <div class=" col-4 mx-auto" style="font-size: 20px">
            ZJ·魂·S01
          </div>
          <div class=" col-4 mx-auto" style="font-size: 20px">
            ZJ·魂·S02
          </div>
          <div class=" col-4 mx-auto" style="font-size: 20px">
            ZJ·魂·S03
          </div>
        </div>
        <div style="width: 100%;height: 2px;background-color: #fff;margin-top: 20px;"></div>
        <div style="font-size: 15px;margin-top: 20px">详细配置请联系客服/销售：400-001-8093</div>
      </div>

      <div class="Rotationprompt" style="">
        *具体型号联系客服安装定制
      </div>
    </div>


  </div>
</template>

<script>
import Swiper from 'swiper';
var mySwiper = null
import { getList, carouselList } from '../utils/qualifica'
export default {
  /* eslint-disable */
  name: 'HomeIndex',
  components: {
    // NavList,
    // CopyRight
  },
  data() {
    return {
      qualificalist: [],
      BroadcastList: [],
      TopbroadcastList:[]
    }
  },
  mounted() {
    //获取公司资质
    this.zzList()

    carouselList({
      state: 0,
      pagelocation:1
    }).then((res) => {
      console.log(res.data);
      this.BroadcastList = res.data
      this.generateSwiperSlides()
    })
    carouselList({
      state: 0,
      pagelocation:2
    }).then((res) => {
      console.log(res.data);
      this.TopbroadcastList = res.data
    })
  },
  methods: {
    zzList() {
      getList({
        state: 0
      }).then((res) => {
        // console.log(res);
        this.qualificalist = res.data
      })
    },
    generateSwiperSlides() {
      // 获取要添加元素的父级容器
      var parentElement = document.getElementById('lbt');

      // 假设要添加的元素数量
      var numberOfSlides = 5;  // 例如添加5个元素

      // 循环创建需要添加的 swiper-slide 元素
      for (var i = 0; i < this.BroadcastList.length; i++) {
        // 创建一个新的 div 元素
        var newSlide = document.createElement('div');
        newSlide.className = 'swiper-slide orange-slide';
        newSlide.style.paddingLeft = '30px';
        newSlide.style.paddingRight = '30px';
        newSlide.style.width = '80%';

        // 创建内部的 banDetail 元素
        var banDetail = document.createElement('div');
        banDetail.className = 'banDetail';

        // 创建内部的链接元素
        var linkElement = document.createElement('a');
        linkElement.className = 'pic';
        linkElement.href = '#';
        linkElement.target = '_blank';

        // 创建链接内部的图片元素
        var imageElement = document.createElement('img');
        imageElement.src = 'imgs' + this.BroadcastList[i].url;
        imageElement.alt = '';
        imageElement.style.width = '100%';

        // 将图片元素添加到链接元素中
        linkElement.appendChild(imageElement);

        // 将链接元素添加到 banDetail 元素中
        banDetail.appendChild(linkElement);

        // 将 banDetail 元素添加到新创建的 swiper-slide 元素中
        newSlide.appendChild(banDetail);

        // 将新创建的 swiper-slide 元素添加到父级容器中
        parentElement.appendChild(newSlide);

        // 添加点击事件（假设你使用了 Vue.js，可以用 @click="mySwiper.swipePrev()"）
        newSlide.addEventListener('click', function () {
          mySwiper.swipePrev();
        });

        if (this.BroadcastList.length - 1 == i) {
          mySwiper = new Swiper('.swiper-container', {
            pagination: '.pagination',
            paginationClickable: true,
            autoplay: 4000,
            loop: true,
            loopedSlides: 2,
            loopAdditionalSlides: 2, //loop模式下slides数量增加个数
            centeredSlides: true,
            slidesPerView: 'auto'
          })
        }
      }

    }
  }
}
</script>

<style>
.banner .swiper-slide {
  /* height: 100%; */
  opacity: 0.4;
}

.banner .swiper-slide-active {
  opacity: 1;
}

.jianjie1 {
  width: 100%;
  background-size: 100% 100%;
  /* padding-top: 50px;
  padding-left: 70px; */
  /* display: flex;
  flex-direction: column; */
  align-items: start;
  /* animation: slide-in 2s forwards; */
}

.jjtext {
  color: #fff;
  font-weight: 800;
  font-size: 35px;
  animation: slide-in 2s forwards;

}

.animate {
  animation: example 2s infinite;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }

  to {
    transform: translateX(0);
  }
}

.text {
  text-align: start;
  color: #202020;
}

.Headgs {
  font-size: 42px;
  margin-top: 25px;
}

.EngHead {
  font-size: 22px;
}

.gsText {
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 20px;
}

.texBott {
  display: flex;
  margin-top: 30px;
}

.jjconter {
  /* width: 500px;
  max-width: 300px; */
  width: 40%;
  min-width: 300px;
  /* font-size: 20px; */
  font-size: 2vh;
  color: #fff;
  margin-top: 30px;
  text-align: start;
  line-height: 4vh;
  animation: slide-in 2s forwards;
}

.labelBox {
  display: flex;
  color: #fff;
  font-size: 4vh;
  animation: slide-in 2s forwards;
}

.lablist {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-top: 50px
}

.bgtext {
  font-size: 6vh;
  animation: slide-in 2s forwards;
}

.smtext {
  font-size: 2vh;
  animation: slide-in 2s forwards;
}



@keyframes top-in {
  from {
    transform: translateY(-100%);
    visibility: visible;
  }

  to {
    transform: translateY(0);
  }
}

.botText{
  background-color: rgb(230, 230, 230);
}

.ProductBox {
  width: 100%;
  min-height: 200px;
  margin-top: 50px;
  font-size: 3vh;
  font-weight: 600;
}

.producthead {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: top-in 4s forwards;
}

.textbor {
  width: 30px;
  height: 3px;
  background: #000;
  margin-top: 10px;
}

.Listbox {
  /* display: flex; */
  margin-bottom: 50px;
  /* justify-content: space-around; */
  margin-top: 50px;
}

.ProductList {
  float: left;
}


.listImg {
  transition: transform 0.3s ease;
  /* 动画效果 */
  width: 300px;
}

.listImg:hover {
  transform: scale(1.2);
  /* 放大到1.2倍 */
}

.hengx {
  width: 55px;
  height: 4px;
  background-color: #4d4d4d;
}

.centcont {
  display: flex;
  align-items: flex-end;
}

.pinptext {
  font-size: 19px;
  margin-left: 5px;
  padding-bottom: 12px;
}

.smalltext {
  font-size: 18px;
  font-weight: 400;
}

.righttext {
  font-size: 18px;
  font-weight: 400;
}

.rightimg {
  max-width: 600px;
  max-height: 400px;
  margin-top: 20px;
}

.botTex {
  width: 100%;
  height: 100%;
  background-color: #e6e6e6;
  min-height: 333px;
}

.botBox {
  min-height: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.BriefBox {
  font-size: 18px;
  color: #9d9da0;
  line-height: 34px;
  text-align: start;
}

.Rotationprompt {
  font-size: 13px;
  color: #9d9da0;
  margin-top: 30px;
  padding-bottom: 30px;
}

.Bottombox {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  min-height: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hbox {
  min-height: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lbox {
  font-size: 18px;
  color: #9d9da0;
  line-height: 34px;
}

.gmzntext {
  font-size: 20px;
  color: #454442;
  margin-top: 12px;
}

.gmznjj {
  font-size: 17px;
  color: #616161;
}
</style>