<template>
  <div style="margin-top:75px;">
    <div class="w-100 headimg1">
      <!-- <img class="w-100" src="../assets/product/banner.jpg" alt=""> -->
      <div style="margin-top: 300px;font-size: 30px;">创智电梯安装案例</div>
      <div style="margin-top: 20px;font-size: 20px;">INSTALLATION CASE</div>

      <div style="display: flex;margin-top: 310px;justify-content: space-around;width: 1024px; cursor: pointer;">
        <div>中建·梅溪湖壹号</div>
        <div>黔江一号</div>
        <div>湘潭东方名苑</div>
        <div>万佳天玺</div>
        <div>襄阳碧桂园</div>
      </div>
    </div>
    <div class="ProductBox container" style="width: 50%;margin-bottom: 100px;">
      <div class="producthead prhead">
        <div>安装案例</div>
        <div style="width: 30px;height: 1px;background-color: black;margin-top: 10px;"></div>
      </div>
      <div class="w-100" style="font-size: 12px;margin-top: 20px;">
        私人定制设计，每一套都不同，适用多种住宅风格
      </div>

      <div class="w-100">
        <div class="Listbox row" style="margin-bottom: 100px">
          <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
            <div class="xwbox w-100 h-100" style="position: relative;">
              <img class="xwimg" src="../assets/installationCase/i2.jpg" alt="">
              <div style="position: absolute;top: 35%;left: 35%;color: #fff;font-size: 20px;">中建·梅溪湖壹号</div>
              <div style="position: absolute;top: 53%;left: 40%;color: #fff;font-size: 12px;border: 1px solid #fff;border-radius: 10px;padding: 5px 15px;">了解详情 ></div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
            <div class="xwbox w-100 h-100" style="position: relative;">
              <img class="xwimg" src="../assets/installationCase/i3.jpg" alt="">
              <div style="position: absolute;top: 35%;left: 41%;color: #fff;font-size: 20px;">黔江一号</div>
              <div style="position: absolute;top: 53%;left: 40%;color: #fff;font-size: 12px;border: 1px solid #fff;border-radius: 10px;padding: 5px 15px;">了解详情 ></div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
            <div class="xwbox w-100 h-100" style="position: relative;">
              <img class="xwimg" src="../assets/installationCase/i4.jpg" alt="">
              <div style="position: absolute;top: 35%;left: 38%;color: #fff;font-size: 20px;">湘潭东方名苑</div>
              <div style="position: absolute;top: 53%;left: 40%;color: #fff;font-size: 12px;border: 1px solid #fff;border-radius: 10px;padding: 5px 15px;">了解详情 ></div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
            <div class="xwbox w-100 h-100" style="position: relative;">
              <img class="xwimg" src="../assets/installationCase/i5.jpg" alt="">
              <div style="position: absolute;top: 35%;left: 41%;color: #fff;font-size: 20px;">万住天玺</div>
              <div style="position: absolute;top: 53%;left: 40%;color: #fff;font-size: 12px;border: 1px solid #fff;border-radius: 10px;padding: 5px 15px;">了解详情 ></div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
            <div class="xwbox w-100 h-100" style="position: relative;">
              <img class="xwimg" src="../assets/installationCase/i6.jpg" alt="">
              <div style="position: absolute;top: 35%;left: 39.5%;color: #fff;font-size: 20px;">襄阳碧桂园</div>
              <div style="position: absolute;top: 53%;left: 40%;color: #fff;font-size: 12px;border: 1px solid #fff;border-radius: 10px;padding: 5px 15px;">了解详情 ></div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
            <div class="xwbox w-100 h-100" style="position: relative;">
              <img class="xwimg" src="../assets/installationCase/i7.jpg" alt="">
              <div style="position: absolute;top: 35%;left: 37%;color: #fff;font-size: 20px;">中粮北纬28度</div>
              <div style="position: absolute;top: 53%;left: 40%;color: #fff;font-size: 12px;border: 1px solid #fff;border-radius: 10px;padding: 5px 15px;">了解详情 ></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- <div style="margin-top: 100px;">
    <div class="jianjie w-100 h-80"></div>

    <div class="ProductBox container">
      <div class="producthead">
        <div>安装案例</div>
        <div class="textbor"></div>
      </div>

      <div class="Listbox row">
        <div class="col-lg-3 col-md-6 col-sm-12 p-3 mt-3" v-for="(item, index) in caseList" :key="index">
          <div class="azbg"  :style ='{backgroundImage:"url ( " + "imgs"+ item.url + " ) " } ' >{{item.text}}</div>
        </div>

      </div>

    </div>
  </div> -->
</template>

<script>
import { getList } from '../utils/InstallationCase'
export default {
  data() {
    return {
      caseList: []
    }
  },
  mounted() {
    //获取案例列表
    this.alList()

  },
  methods: {
    alList() {
      getList({
        state: 0
      }).then((res) => {
        // console.log(res);
        this.caseList = res.data
      })
    }
  }

}
</script>

<style>
.jianjie {
  width: 100%;
  /* height: 600px; */
  min-height: 600px;
  background-image: url('../assets/banner/image.jpg');
  background-size: 100% 100%;
  padding-top: 50px;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* animation: slide-in 2s forwards; */
}

.azbg {
  background-image: url('../assets/banner/banner.jpg');
  background-size: 100% 100%;
  /* height: 200px; */
  min-height: 150px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slide-in 2s forwards; */
  transition: transform 0.3s ease;
  /* 动画效果 */
  cursor: pointer;

}

.azbg:hover {
  transform: scale(1.2);
  /* 放大到1.2倍 */
}

.prhead {
  font-size: 20px;
}

.xwbox {
  min-height: 150px;
  /* border: 1px solid red; */
  background: #FBFBFB;
  transition: transform 0.3s ease;
  /* 动画效果 */
  cursor: pointer;
}

.xwbox:hover {
  transform: scale(1.1);
  /* 放大到1.2倍 */
}

.xwimg {
  width: 100%;
  /* height: 200px; */
}
.headimg1{
    width: 100%;
    background-image: url('../assets/installationCase/i1.jpg');
    height: 750px;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>