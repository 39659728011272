<template>
    <div class="conter">
        <div class="box">
            <nav class="navbar navbar-expand-lg ">
                <div class="container-fluid">
                    <a class="navbar-brand d-none d-lg-block" href="#" style="margin-right: 170px;">
                        <img src='@/assets/newlogo_03.png' alt="" style="width: 160px;" class="logoimg" @click="logoClick">
                    </a>
                    <a class="navbar-brand d-lg-none" href="#" style="">
                        <img src='@/assets/newlogo_03.png' alt="" style="width: 160px;" class="logoimg" @click="logoClick">
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{ 'show': isNavbarOpen }"
                        style="margin-left: 0px;">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-none d-lg-block">
                            <!--  -->
                            <li class="nav-item boxnav" :class="active == index ? 'navActive' : ''"
                                style="margin-right: 30px;" v-for="(item, index) in navlist" :key="index"
                                @mouseenter="showSubmenu(index)" @mouseleave="hideSubmenu(index)"
                                @click="NavClick(index)">
                                <a class="nav-link" :class="active == index ? 'active' : ''" aria-current="page"
                                    href="#">{{ item.name }}</a>

                                <div v-show="currentSubmenu === index" class="submenu">
                                    <div class="hoverSub" v-for="subItem in item.subItems" :key="subItem.id" style=""
                                        @click="subClick(subItem,index)">
                                        {{ subItem.title }}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none">
                            <!--  -->
                            <li class="nav-item boxnav dropdown" :class="active == index ? 'navActive' : ''"
                                v-for="(item, index) in navlist" :key="index" @click="NavClick(index)">
                                <a class="nav-link dropdown-toggle" :class="active == index ? 'active' : ''" aria-current="page" v-if="item.subItems"
                                    href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ item.name }}</a>

                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#" v-for="subItem in item.subItems" :key="subItem.id"  @click="subClick(subItem,index)"> {{ subItem.title }}</a></li>
                                </ul>

                                <a class="nav-link" :class="active == index ? 'active' : ''" aria-current="page"  v-if="!item.subItems"
                                    href="#">{{ item.name }}</a>

                            </li>
                        </ul>
                        <form class="d-flex flex-form" role="search">
                            <!-- <img class="enImg" src="../assets/en.png" alt="">
                            <div class="language">中文</div>
                            <div> / </div>
                            <div class="language">EN</div> -->
                        </form>
                    </div>

                </div>
            </nav>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavList',
    data() {
        return {
            // https://sdzjdt.com/img/download/image-1715305498596.png
            env: 'imgs/download/image-1715065045869.png',
            isNavbarOpen: false,
            active: 0,
            currentSubmenu: null,
            navlist: [
                {
                    name: '首页',
                    tourl: '/',
                },
                {
                    name: '产品展示',
                    tourl: '/ProductDisplay',

                },
                //    subItems: [
                // { id: 1, title: '乘客电梯', tourl: '/ProductDisplay', },
                //         { id: 2, title: '观光电梯', tourl: '/ProductDisplay', },
                //         { id: 3, title: '载货电梯', tourl: '/ProductDisplay', },
                //         { id: 4, title: '家用电梯', tourl: '/ProductDisplay', },
                //         { id: 5, title: '扶梯', tourl: '/ProductDisplay', },
                //     ],
                {
                    name: '安装案例',
                    tourl: '/InstallationCase',
                },
                {
                    name: '新闻资讯',
                    tourl: '/NewsInformation',
                },
                {
                    name: '关于我们',
                    tourl: '/AboutUs',
                },
                {
                    name: '联系我们',
                    tourl: '/ContactUs',
                },
            ]
        };
    },
    mounted() {
        // console.log(this.env);
        this.routerof()
    },
    methods: {
        showSubmenu(index) {
            console.log(index);
            this.currentSubmenu = index;
        },
        hideSubmenu() {
            this.currentSubmenu = null;
        },
        logoClick() {
            this.$router.push('/');
        },
        toUrlso(e) {
            this.active = e
            this.NavClick(e)
        },
        routerof() {
            this.navlist.forEach((list, i) => {
                if (list.tourl == this.$route.path) {
                    this.active = i
                }
            })
        },
        NavClick(index) {
            if (this.navlist[index].subItems && this.navlist[index].subItems.length > 0) {
                return
            }
            if (this.$route.path !== this.navlist[index].tourl) {
                console.log(this.$route.path, this.navlist[index].tourl);
                this.$router.push(this.navlist[index].tourl);
            }
            this.isNavbarOpen = false
            this.active = index;

            const myBox = document.getElementById('navbarSupportedContent');
            myBox.classList.remove('show');

        },
        subClick(e,index) {
            console.log(e.tourl);

            if (this.$route.path !== e.tourl) {
                console.log(this.$route.path, e.tourl);
                this.$router.push(e.tourl);
            }
            this.isNavbarOpen = false
            this.active = index;

            const myBox = document.getElementById('navbarSupportedContent');
            myBox.classList.remove('show');
        },
        closeNavbar() {
            console.log(this.isNavbarOpen);

            this.isNavbarOpen = true
        }
    }

}
</script>

<style>
.nav-item {
    position: relative;
    list-style: none;
}

.submenu {
    /* display: none; */
    position: absolute;
    left: 0;
    top: 100%;

    padding-top: 20px;
}

.hoverSub {
    color: #000;
    background: #fff;
    width: 120px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hoverSub:hover {
    color: rgba(240, 140, 0, 1);
}

.logoimg {
    animation: slide-in 2s forwards;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
        visibility: visible;
    }

    to {
        transform: translateX(0);
    }
}

.conter {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    /* height: 105px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background: #fff;
    /* margin-top: 10px; */
}

.box {
    margin: 0 auto;
    width: 80%;
    /* height: 100%; */
}

.navActive {
    border-bottom: 3px solid #151316;
}

.nav-link {
    color: #000;
    font-size: 20px;
}

.boxnav {
    color: #808080;
    /* margin-right: 20px; */
    min-width: 120px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    /* transition: left 0.6s ease-out; */
}

.boxnav::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 0;
    height: 3px;
    background-color: #151316;
    /* 下边框的颜色 */
    transition: width 0.3s ease;
    /* 过渡效果 */
}

.boxnav:hover::after {
    width: 100%;
    /* 下边框宽度变为100% */
}

.active {
    color: #000 !important;
    font-weight: 800;
}

.flex-form {
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    /* cursor: url("../assets/cursor.png"), pointer; */
}

.language:hover {
    color: red;
}

.enImg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
</style>